var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", {}, [
      _c(
        "div",
        {
          staticClass:
            "blog-pages-wrapper section-space--ptb_100 border-bottom",
        },
        [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-8" }, [
                _c("div", { staticClass: "main-blog-wrap" }, [
                  _c("div", { staticClass: "single-blog-item" }, [
                    _c(
                      "div",
                      {
                        staticClass: "post-info lg-blog-post-info  wow move-up",
                      },
                      [
                        _c(
                          "h4",
                          { staticClass: "post-title font-weight--light" },
                          [_vm._v(" Termos de Uso ")]
                        ),
                        _c("div", { staticClass: "post-excerpt mt-15" }, [
                          _c("p", [
                            _vm._v(" Bem-vindo ao portal ou aplicativo "),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              ". A seguir apresentaremos a você (usuário) os Termos de Uso e Condições de Navegação, documento que relaciona as principais regras que devem ser observadas por todos que acessam este site. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " Ao visitar ou utilizar este site ou aplicativo, pressupõe-se a leitura e a concordância com todos os termos a seguir. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " Para os fins deste documento, devem se considerar as seguintes definições e descrições para seu melhor entendimento: "
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "row portfolio-details-feature-wrap",
                            },
                            [
                              _c("div", { staticClass: "col-lg-12" }, [
                                _c(
                                  "div",
                                  { staticClass: "portfolio-details-content" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "portfolio-details-table mt-30",
                                      },
                                      [
                                        _c(
                                          "table",
                                          { staticClass: "mb-0 table-th" },
                                          [
                                            _c(
                                              "tr",
                                              {
                                                staticClass:
                                                  "portfolio-details-tags",
                                              },
                                              [
                                                _c(
                                                  "td",
                                                  { staticClass: "label" },
                                                  [_vm._v("Educatena:")]
                                                ),
                                                _c("td", [
                                                  _vm._v(
                                                    " denominação utilizada nestes Termos de Uso e Condições de Navegação para identificar a "
                                                  ),
                                                  _c("strong", [
                                                    _vm._v("Educatena"),
                                                  ]),
                                                  _vm._v(
                                                    ", pessoa jurídica de direito privado com sede Brasília, Distrito Federal e inscrita no CNPJ sob o nº 36.074.244/0001-66. "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "tr",
                                              {
                                                staticClass:
                                                  "portfolio-details-tags",
                                              },
                                              [
                                                _c(
                                                  "td",
                                                  { staticClass: "label" },
                                                  [_vm._v("Anti-spam:")]
                                                ),
                                                _c("td", [
                                                  _vm._v(
                                                    " sistema que evita correspondências eletrônicas indesejadas, como publicidade em massa, pelo bloqueio de mensagens ou as movendo para pasta específica. "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "tr",
                                              {
                                                staticClass:
                                                  "portfolio-details-tags",
                                              },
                                              [
                                                _c(
                                                  "td",
                                                  { staticClass: "label" },
                                                  [_vm._v("Aplicativo:")]
                                                ),
                                                _c("td", [
                                                  _vm._v(
                                                    " programa de computador que ajuda o usuário a desempenhar uma tarefa específica. "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "tr",
                                              {
                                                staticClass:
                                                  "portfolio-details-tags",
                                              },
                                              [
                                                _c(
                                                  "td",
                                                  { staticClass: "label" },
                                                  [_vm._v("Aplicativo spider:")]
                                                ),
                                                _c("td", [
                                                  _vm._v(
                                                    " programa desenvolvido para obter informações de modo automatizado na internet, navegando pela web (teia) como se fosse uma spider (aranha). "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "tr",
                                              {
                                                staticClass:
                                                  "portfolio-details-tags",
                                              },
                                              [
                                                _c(
                                                  "td",
                                                  { staticClass: "label" },
                                                  [_vm._v("Login:")]
                                                ),
                                                _c("td", [
                                                  _vm._v(
                                                    " nome de usuário escolhido pelo visitante quando preenche o cadastro para acesso a funcionalidades exclusivas do portal ou aplicativo. "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "tr",
                                              {
                                                staticClass:
                                                  "portfolio-details-tags",
                                              },
                                              [
                                                _c(
                                                  "td",
                                                  { staticClass: "label" },
                                                  [_vm._v("Logon:")]
                                                ),
                                                _c("td", [
                                                  _vm._v(
                                                    " ato do visitante cadastrado para ingressar na área restrita do portal ou aplicativo, utilizando seu login e senha. "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "tr",
                                              {
                                                staticClass:
                                                  "portfolio-details-tags",
                                              },
                                              [
                                                _c(
                                                  "td",
                                                  { staticClass: "label" },
                                                  [_vm._v("Layout:")]
                                                ),
                                                _c("td", [
                                                  _vm._v(
                                                    " conjunto compreendido entre aparência, design e fluxos do portal ou aplicativo. "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "tr",
                                              {
                                                staticClass:
                                                  "portfolio-details-tags",
                                              },
                                              [
                                                _c(
                                                  "td",
                                                  { staticClass: "label" },
                                                  [_vm._v("Link:")]
                                                ),
                                                _c("td", [
                                                  _vm._v(
                                                    " terminologia para endereço de internet. "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "tr",
                                              {
                                                staticClass:
                                                  "portfolio-details-tags",
                                              },
                                              [
                                                _c(
                                                  "td",
                                                  { staticClass: "label" },
                                                  [_vm._v("Logs:")]
                                                ),
                                                _c("td", [
                                                  _vm._v(
                                                    " registros de atividades do usuário efetuadas no portal ou aplicativo. "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "tr",
                                              {
                                                staticClass:
                                                  "portfolio-details-tags",
                                              },
                                              [
                                                _c(
                                                  "td",
                                                  { staticClass: "label" },
                                                  [
                                                    _vm._v(
                                                      "Portal ou Aplicativo:"
                                                    ),
                                                  ]
                                                ),
                                                _c("td", [
                                                  _vm._v(
                                                    " designa o endereço eletrônico "
                                                  ),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "hover-style-link",
                                                      attrs: {
                                                        href: "https://educatena.com.br",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "https://educatena.com.br"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    " e seus subdomínios. "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "tr",
                                              {
                                                staticClass:
                                                  "portfolio-details-tags",
                                              },
                                              [
                                                _c(
                                                  "td",
                                                  { staticClass: "label" },
                                                  [_vm._v("Site:")]
                                                ),
                                                _c("td", [
                                                  _vm._v(
                                                    " denominação para páginas de internet. "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "tr",
                                              {
                                                staticClass:
                                                  "portfolio-details-tags",
                                              },
                                              [
                                                _c(
                                                  "td",
                                                  { staticClass: "label" },
                                                  [_vm._v("Usuários:")]
                                                ),
                                                _c("td", [
                                                  _vm._v(
                                                    " pessoas (física ou jurídica) que visitam, acessam ou interagem com atividades, produtos e serviços oferecidos pelo portal ou aplicativo por meio de sua conta de acesso. "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "tr",
                                              {
                                                staticClass:
                                                  "portfolio-details-tags",
                                              },
                                              [
                                                _c(
                                                  "td",
                                                  { staticClass: "label" },
                                                  [_vm._v("Webmastering:")]
                                                ),
                                                _c("td", [
                                                  _vm._v(
                                                    " compreende criação, programação, desenvolvimento, controle e disponibilidade de páginas de internet. "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _c("p"),
                          _c(
                            "h5",
                            { staticClass: "post-title font-weight--light" },
                            [
                              _vm._v(
                                " 1. Aceite dos Termos de Uso e Política de Privacidade "
                              ),
                            ]
                          ),
                          _c("p", [
                            _vm._v(
                              " 1.1. Ao acessar este portal ou aplicativo, o usuário concorda expressamente e aceita integralmente as disposições destes Termos de Uso e da Política de Privacidade. "
                            ),
                          ]),
                          _c(
                            "h5",
                            { staticClass: "post-title font-weight--light" },
                            [
                              _vm._v(
                                " 2. Acesso e restrições de funcionalidade "
                              ),
                            ]
                          ),
                          _c("p", [
                            _vm._v(
                              " 2.1. Este portal ou aplicativo é dedicado ao atendimento dos usuários da "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              ", a fim de proporcionar uma experiência completa na aquisição ou acesso de produtos e serviços oferecidos bem como para que saibam mais sobre cada um deles de forma interativa e dinâmica. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 2.2. Não é permitido aos usuários acessar as áreas de programação do portal ou aplicativo, seu banco de dados ou qualquer outro conjunto de informações que faça parte da atividade de webmastering, ficando sujeito quem o fizer à legislação brasileira e desde já obrigado a reparar os danos que causar. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 2.3. Também não é autorizado realizar ou permitir engenharia reversa, nem traduzir, decompilar, compilar, copiar, modificar, reproduzir, alugar, sublicenciar, publicar, divulgar, transmitir, emprestar, distribuir ou, de qualquer outra maneira, dispor das ferramentas de consulta deste portal ou aplicativo e de suas funcionalidades. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 2.4. Neste portal ou aplicativo é terminantemente proibida a utilização de aplicativos spider ou de mineração de dados, de qualquer tipo ou espécie, além de outro aqui não tipificado, mas que atue de modo automatizado, seja para realizar operações massificadas, seja para quaisquer outras finalidades, ficando sujeito quem o fizer à legislação brasileira e desde já obrigado a reparar os danos que causar. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 2.4.1. Este portal ou aplicativo foi desenvolvido para que o usuário possa aprofundar o seu conhecimento mediante a aquisição ou o acesso a produtos e serviços oferecidos pela "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              ", sendo vedado uso diverso do contratado na plataforma. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 2.5. O acesso a determinados recursos e funcionalidades do nosso portal ou aplicativo poderá exigir de você, usuário, a coleta de determinadas informações classificadas, segundo a definição da Lei Federal 13.709/18, como pessoais. Para que possa obter mais informações sobre a forma de coleta e tratamento dessas informações pela "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              ", recomendamos a leitura de nossa Política de Privacidade, disponível no site "
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "hover-style-link",
                                attrs: { href: "https://educatena.com.br" },
                              },
                              [_vm._v("https://educatena.com.br")]
                            ),
                            _vm._v(". "),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 2.6. É ônus dos usuários arcar com todos os meios necessários para a navegação neste portal ou aplicativo , inclusive o preenchimento dos requisitos mínimos para o acesso e a navegabilidade na internet e a obtenção dos meios necessários para essa finalidade. "
                            ),
                          ]),
                          _c(
                            "h5",
                            { staticClass: "post-title font-weight--light" },
                            [
                              _vm._v(
                                " 3. Informações gerais sobre o site ou aplicativo e seu funcionamento "
                              ),
                            ]
                          ),
                          _c("p", [
                            _vm._v(
                              " 3.1. Este portal ou aplicativo é apresentado ao público da web ou do app na maneira como foi desenvolvido e como está disponível, podendo, todavia, passar por constantes aprimoramentos e atualizações, não sendo a "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " obrigada a manter determinada estrutura ou layout, salvo por sua própria conveniência e interesse. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(" 3.2. A "),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " envida todos os seus esforços para a disponibilidade contínua, permanente e integral do Portal ou Aplicativo, ressalvada, porém, a possibilidade de ocorrência de eventos extraordinários, imprevisíveis ou que fogem da sua esfera de vigilância e responsabilidade, tais como, exemplificativamente, desastres naturais, falhas ou colapsos nos sistemas centrais de comunicação e de acesso à internet ou fatos causados por terceiros. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(" 3.3. A "),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " não se responsabiliza por nenhum dano, prejuízo ou perda nos equipamentos dos usuários que tenham sido causadas por impossibilidades técnicas, indisponibilidades ou falhas no sistema, no servidor ou na conexão à internet, ainda que decorrentes das ações ou omissões dos usuários ou de terceiros, inclusive por ações de softwares maliciosos, tais como vírus, cavalos de troia, transferência de dados, arquivos, imagens, textos, áudios, vídeos, documentos, conteúdos carregados, enviados e/ou transmitidos ao portal ou aplicativo pelos usuários, como também pela instalação, no equipamento do usuário ou de terceiros, de vírus, trojans, malware, worm, bot, backdoor, spyware, rootkit ou de quaisquer outros dispositivos, existentes ou que ainda venham a ser criados, em decorrência da navegação na internet pelo usuário. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 3.4. Os usuários não têm nenhum direito para exigir a disponibilidade do portal ou aplicativo conforme melhor lhes convir, tampouco poderão pleitear a reparação de danos na hipótese de o portal ou aplicativo permanecer fora do ar, independentemente do motivo. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(" 3.5. A "),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " não se responsabiliza pelos atos praticados por usuários em seu portal ou aplicativo , devendo a cada um ser imputada a responsabilidade de acordo com as suas próprias ações ou omissões. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 3.6. Os usuários não poderão praticar as seguintes ações em razão ou por meio da utilização do portal ou aplicativo: "
                            ),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 3.6.1. prática de quaisquer atos ilícitos e/ou violação da legislação vigente; "
                            ),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 3.6.2. atos contrários à moral e aos bons costumes; "
                            ),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 3.6.3. violação de direitos de terceiros; "
                            ),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 3.6.4. violação dos direitos de sigilo e privacidade alheios; "
                            ),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 3.6.5. atos que causem ou propiciem a contaminação ou prejudiquem a operação de quaisquer equipamentos da "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              ", inclusive por meio de vírus, trojans, malware, worm, bot, backdoor, spyware, rootkit, ou de quaisquer outros dispositivos, existentes ou que ainda venham a ser criados; "
                            ),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 3.6.6. praticar quaisquer atos que, direta ou indiretamente, no todo ou em parte, possam causar prejuízo à "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              ", a qualquer usuário e/ou a quaisquer terceiros; "
                            ),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 3.6.7. usar qualquer nome empresarial, marca, registro, nome de domínio, slogan ou qualquer sinal distintivo ou bem de propriedade intelectual de titularidade da "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(" ou de terceiros. "),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 3.7. Os usuários são exclusivamente responsáveis: "
                            ),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 3.7.1. por todos e quaisquer atos ou omissões por ele realizados a partir de seu acesso à internet ou ao portal ou aplicativo; "
                            ),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 3.7.2. por todo e qualquer conteúdo por ele carregado, enviado e/ou transmitido ao portal ou aplicativo; "
                            ),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 3.7.3. pela reparação de todos e quaisquer danos, diretos ou indiretos (inclusive decorrentes de violação de quaisquer direitos de outros usuários, de terceiros, inclusive direitos de propriedade intelectual, de sigilo e de personalidade), que sejam causados à "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              ", a qualquer outro usuário ou a qualquer terceiro, inclusive em virtude do descumprimento do disposto nestes Termos de Uso e Política de Privacidade ou de qualquer ato praticado a partir de seu acesso à internet ou ao portal ou aplicativo. "
                            ),
                          ]),
                          _c(
                            "h5",
                            { staticClass: "post-title font-weight--light" },
                            [
                              _vm._v(" 4. Comunicação da "),
                              _c("strong", [_vm._v("Educatena")]),
                              _vm._v(" com usuários "),
                            ]
                          ),
                          _c("p", [
                            _vm._v(" 4.1. A "),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " utilizará como canal de comunicação com os seus usuários o e-mail informado durante a interação com o portal ou aplicativo, sem prejuízo de usar os demais meios adequadamente informados pelo usuário. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 4.1.1. A responsabilidade pelo recebimento dos comunicados e das respostas é exclusiva dos usuários, motivo pelo qual é indispensável que forneçam os dados corretos e precisos à "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(", além de os manterem atualizados. "),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 4.1.2. Cabe ao usuário configurar o seu endereço eletrônico e seus sistemas eletrônicos anti-spam de modo a não interferir no recebimento de mensagens eletrônicas da "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              ", não se admitindo reclamações ou insurgências caso o não recebimento ou recebimento intempestivo da mensagem ocorra em razão de filtros, bloqueios ou mecanismos congêneres instalados ou configurados pelos usuários. "
                            ),
                          ]),
                          _c(
                            "h5",
                            { staticClass: "post-title font-weight--light" },
                            [
                              _vm._v(
                                " 5. Privacidade dos usuários no portal ou aplicativo "
                              ),
                            ]
                          ),
                          _c("p", [
                            _vm._v(" 5.1. A "),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " possui um documento próprio, denominado Política de Privacidade, que regula o tratamento das informações pessoais coletadas por meio deste portal ou aplicativo. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 5.2. A Política de Privacidade é parte integrante e inseparável do presente documento de Termos de Uso e poderá ser acessada pelo usuário em "
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "hover-style-link",
                                attrs: { href: "https://educatena.com.br" },
                              },
                              [_vm._v("https://educatena.com.br")]
                            ),
                            _vm._v(". "),
                          ]),
                          _c("p", [
                            _vm._v(" 5.3. A "),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " declara que os dados dos usuários somente serão utilizados para as finalidades específicas descritas no presente documento, bem como na Política de Privacidade deste portal ou aplicativo. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 5.4. Havendo conflito deste Termo de Uso e Condições de Navegação com a Política de Privacidade, prevalecerá o conteúdo da norma mais específica. "
                            ),
                          ]),
                          _c(
                            "h5",
                            { staticClass: "post-title font-weight--light" },
                            [_vm._v(" 6. Obrigações do portal ou aplicativo ")]
                          ),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(" 6.1. A "),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(" se obriga, com seus usuários, a: "),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 6.1.1. Manter o ambiente virtual seguro, salvo por ato destrutivo ou lesivo praticado por terceiro que vá além dos esforços empenhados pela "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              ", hipótese em que não se responsabilizará por danos oriundos de prática danosa ou nociva promovida por outrem. "
                            ),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 6.1.2. Preservar a funcionalidade do site ou aplicativo, com links acessíveis, utilizando layout que respeita a usabilidade e a navegabilidade do usuário, de modo a facilitar a navegação sempre que possível. "
                            ),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 6.1.3. Exibir as funcionalidades de maneira clara, completa, precisa e suficiente de modo que exista a exata percepção das operações realizadas. "
                            ),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 6.1.4. Garantir, por meio do estado da técnica disponível, o sigilo e a confidencialidade dos dados fornecidos ou inseridos nas funcionalidades oferecidas em seu portal ou aplicativo. "
                            ),
                          ]),
                          _c(
                            "h5",
                            { staticClass: "post-title font-weight--light" },
                            [_vm._v(" 7. Obrigações dos usuários ")]
                          ),
                          _c("p", [
                            _vm._v(
                              " 7.1. O usuário se obriga a realizar uma navegação pautada na ética, no respeito aos direitos da "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " e de terceiros e na contínua observância das regras e condições que regem a utilização do portal ou aplicativo. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 7.2. É dever do usuário fornecer dados e informações verídicas e autênticas à "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " bem como utilizar os recursos e mecanismos do portal ou aplicativo de acordo com as finalidades para as quais foi concebido, sob pena de responsabilização, nos termos da lei, de indenizar a quem causar dano e de ter o seu acesso ao presente portal ou aplicativo bloqueado ou definitivamente excluído. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 7.3. Na incidência de danos ao portal ou aplicativo ou a terceiros, o responsável se compromete a arcar com todas as obrigações de indenizar os sujeitos lesados, excluindo expressamente a "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(" de responder pelos prejuízos. "),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 7.4. Também é dever do usuário manter o sigilo dos dados relacionados ao seu acesso, não divulgando ou compartilhando o seu login, senha ou qualquer outro meio de acesso a quem quer que seja, responsabilizando-se, nos termos da lei, a indenizar a quem causar dano, ter o seu acesso ao presente portal ou aplicativo bloqueado ou definitivamente excluído e de rescisão do respectivo contrato de fornecimento de produtos ou de prestação de serviços. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 7.4.1. Deverá o usuário, se suspeitar que a confidencialidade de seus dados de acesso foi quebrada ou corrompida, proceder à imediata troca de sua senha, se possível, e noticiar o fato à "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " com a maior urgência possível, por meio dos canais de atendimento disponibilizados no portal ou aplicativo. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 7.4.2. Não é recomendada a utilização de senhas óbvias, como datas especiais, nomes ou sequências numéricas. A senha e o login do usuário são de uso privativo e intransferível, não devendo ser em hipótese alguma compartilhados com terceiros. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 7.5. O usuário está ciente e concorda com a coleta, o armazenamento, o tratamento, o processamento e o uso das informações enviadas e/ou transmitidas pelo usuário nos termos e nas condições estabelecidas nestes Termos de Uso e, especialmente, na Política de Privacidade que dele faz parte. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 7.6. As informações dos usuários são coletadas, armazenadas, tratadas, processadas e utilizadas pela "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(" com as seguintes finalidades: "),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 7.6.1. desenvolver, manter e aperfeiçoar recursos e funcionalidades do portal ou aplicativo; "
                            ),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 7.6.2. possibilitar o acesso e o uso dos recursos e funcionalidades do portal ou aplicativo pelos usuários; "
                            ),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 7.6.3. analisar o desempenho e medir a audiência do portal ou aplicativo, verificar os hábitos de navegação dos usuários no portal ou aplicativo, a forma pela qual chegaram às páginas do portal ou aplicativo, avaliar as estatísticas relacionadas ao número de acessos e forma de uso do portal ou aplicativo, seus recursos e funcionalidades; "
                            ),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 7.6.4. analisar questões relacionadas à segurança do portal ou aplicativo, aperfeiçoamento e desenvolvimento de ferramentas antifraude; "
                            ),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 7.6.5. melhorar as experiências de navegação e interatividade dos usuários no portal ou aplicativo; "
                            ),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 7.6.6. permitir o fornecimento de produtos e serviços personalizados e mais adequados às necessidades dos usuários, tais como páginas de perfil, atualizações, conteúdos exclusivos e anúncios relevantes; "
                            ),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 7.6.7. permitir estudos internos de clusterização, bem como identificar os perfis, hábitos e necessidades para eventuais ofertas de produtos e serviços da "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(". "),
                          ]),
                          _c("p", [
                            _vm._v(" 7.7. A "),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " preserva a privacidade dos usuários e não compartilha, cede ou disponibiliza dados e informações com terceiros, salvo mediante consentimento do próprio usuário, por força de lei ou ordem judicial, ou demais situações específicas previstas em nossa Política de Privacidade do Portal ou Aplicativo, disponível em "
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "hover-style-link",
                                attrs: { href: "https://educatena.com.br" },
                              },
                              [_vm._v("https://educatena.com.br")]
                            ),
                            _vm._v(". "),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 7.8 Nossos sites ou aplicativos podem conter links para sites e aplicativos de terceiros. Se os acessar, você estará sujeito a outros termos de uso e a outras políticas de privacidade. Nossos Termos de Uso e Política de Privacidade só são válidos nos sites e aplicativos da "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              ". A existência desses links não significa nenhuma relação de endosso ou de patrocínio entre "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(" e esses terceiros, isentando a "),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " de qualquer responsabilidade de você com esse terceiro. "
                            ),
                          ]),
                          _c(
                            "h5",
                            { staticClass: "post-title font-weight--light" },
                            [
                              _vm._v(
                                " 8. Direitos autorais e propriedade intelectual de portal ou aplicativo "
                              ),
                            ]
                          ),
                          _c("p", [
                            _vm._v(" 8.1. É vedado o uso comercial do sinal "),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " ou de outros sinais distintivos de titularidade e propriedade da "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              ", como marca, nome empresarial, registro, título de estabelecimento, nome de domínio ou quaisquer outras espécies de sinal distintivo, salvo se houver prévia autorização por escrito da "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(". "),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 8.2. Também é vedado o uso dos programas, bancos de dados, redes e seus arquivos, que igualmente são de propriedade e titularidade da "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              ", salvo se houver prévia autorização por escrito da "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(". "),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 8.3. É permitido o uso dos conteúdos públicos das telas do portal ou aplicativo, incluindo, mas não se limitando, a reprodução, a distribuição, o compartilhamento e a divulgação, total ou parcial, de textos, figuras, gráficos, imagens, desenhos e outros elementos que o compõem, desde que não haja qualquer tipo de alteração em seus dados e conteúdos e que os direitos autorais sejam respeitados com o crédito ao autor e/ou fonte nas divulgações. Deverá ainda fazer menção na divulgação acerca da isenção de responsabilidade da "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              ", conforme estabelecido no item 9.2.1 deste documento. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 8.4. Ao acessar o portal ou aplicativo, os usuários declaram que respeitarão todos os direitos de propriedade intelectual depositados, registrados ou titularizados pela "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              ", protegidos por leis e tratados internacionais que regem os direitos de propriedade intelectual, bem como respeitarão os direitos de terceiros que porventura estiveram, ou ainda estejam, de alguma forma disponíveis no portal ou aplicativo. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 8.4.1. O simples acesso ao portal ou aplicativo não confere ao usuário nenhum direito relacionado à propriedade, ao uso ou à exploração de nomes, títulos, palavras, frases, marcas, patentes, sinais, obras literárias, artísticas, lítero-musicais, imagens, desenhos, dados e informações, entre outros, que nele estiveram ou ainda estejam disponíveis. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 8.5. Na hipótese de existir dúvida acerca da possibilidade ou não de uso de determinado elemento, dado, conteúdo ou informação existente no portal ou aplicativo, o usuário se compromete a submeter uma consulta à "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " previamente ao uso, cabendo exclusivamente à "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " deliberar sobre a concessão, ou não, de uma autorização específica. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 8.6. As eventuais autorizações de uso concedidas pela "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " delimitarão o objeto, a forma e o prazo de uso, de modo que qualquer reutilização do material autorizado ou utilização do conteúdo de forma diversa da autorizada deverá ser precedida, obrigatoriamente, de uma nova autorização por escrito da "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(". "),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 8.7. A autorização para utilização do material solicitado é pessoal e intransmissível, não poderá ser cedida ou transferida a terceiros, ainda que vinculados de qualquer maneira ao sujeito autorizado. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 8.8. A utilização das funcionalidades oferecidas no portal ou aplicativo ocorrerá na forma de prestação de serviços, não conferindo ao usuário nenhum direito sobre o software ou a aplicação utilizada pela "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              ", tampouco sobre as estruturas de informática que sustentam as aplicações de internet e o portal ou aplicativo. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 8.9. A eventual retirada ou modificação de qualquer conteúdo ou funcionalidade do portal ou aplicativo em decorrência de reclamação ou de manifestação do usuário deverá ser sempre compreendida como uma demonstração da intenção da "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " de evitar dissabores e de promover a melhor experiência de navegação ao usuário, não se enquadrando, em nenhuma hipótese, como reconhecimento de culpa ou de qualquer infração pela "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(". "),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 8.10. São dos usuários os direitos autorais sobre os conteúdos enviados à "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " e ao portal ou aplicativo, tais como, mas não limitadamente, comentários, sugestões, ideias, observações, gráficos, vídeos, imagens, representações gráficas, áudios e mensagens, sendo concedida, ao enviá-los à "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              ", uma licença irrevogável, perpétua, de âmbito mundial, irrestrita, isenta de royalties ou de remuneração, não exclusiva e sem qualquer confidencialidade, permitindo-se a reprodução, adaptação, modificação, tradução, edição, publicação, distribuição ou exibição no próprio portal ou aplicativo, possibilitando a melhor utilização das ferramentas existentes ou que vierem a ser criadas ou a ilustração de algum produto, serviço ou atividade por ela oferecido. "
                            ),
                          ]),
                          _c(
                            "h5",
                            { staticClass: "post-title font-weight--light" },
                            [
                              _vm._v(
                                " 9. Das funcionalidades e serviços do portal ou aplicativo "
                              ),
                              _c("strong", [_vm._v("Educatena")]),
                            ]
                          ),
                          _c("p", [
                            _vm._v(" 9.1. A "),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " oferece a seus visitantes diversos produtos e serviços com o objetivo de orientar os profissionais das áreas de educação, ou de interessados em conhecer mais sobre essas especialidades. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 9.2. Todos os índices, números, indicativos e cálculos estimativos não oficiais, determinados pela própria "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              ", somente devem ser utilizados como mera referência de mercado, não necessariamente possuindo a exatidão, precisão ou valor legal de informação oficial, publicada por órgão governamental regulador ou entidade competente para tanto. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(" 9.2.1. A "),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " envida seus esforços para publicar índices, números, indicativos e cálculos estimativos tão precisos quanto aos dos órgãos oficiais que regulamentam e disciplinam essas matérias, mas a efetiva utilização, pelos usuários, dos índices, números, indicativos e cálculos estimativos não oficiais disponibilizados pela "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " correrá exclusivamente por conta e risco dos usuários ou adquirentes dos produtos e serviços, isentando a "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              ", em qualquer caso, de qualquer responsabilidade decorrente de divergência, diferença, defasagem ou inexatidão das referidas informações, ainda que em razão delas tenha havido prejuízos e ainda que tenham elas sido relevantes para a tomada de decisões ou para a definição de estratégias. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(" 9.3. A "),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " não se responsabiliza pela reprodução, transmissão, disponibilização, publicação ou distribuição de parte ou totalidade do conteúdo deste portal ou aplicativo, cabendo a quem praticar tais atos o dever de reparar eventuais danos causados a terceiros. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 9.4. Para a aquisição ou acesso de produtos ou serviços do portal ou aplicativo, é indispensável que o usuário preencha e forneça todas as informações solicitadas de forma veraz e fidedigna, não se responsabilizando a "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " por dissabores, danos, defeitos, vícios ou atrasos na contratação ou no uso dos produtos e serviços decorrentes de operações não concretizadas, ou tardiamente concretizadas, em razão da falta de diligência e de precisão dos usuários. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 9.5. Os logins ou contas de acesso que forem bloqueadas ou definitivamente excluídas em razão de desrespeito ao presente Termo de Uso não estarão sujeitas a nenhuma espécie de indenização. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(" 9.6. O portal ou aplicativo "),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " poderá possuir regras específicas para aquisição ou acesso e uso de produtos ou serviços específicos, cabendo ao usuário diligenciar, no momento da celebração do contrato, acerca da disponibilidade, quantidade e qualidade dos produtos ou serviços adquiridos. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 9.7. Sem prejuízo da retirada ou da exclusão por força de ordem judicial, a "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " se reserva o direito de não publicar ou de excluir conteúdo enviado pelo usuário, a qualquer tempo, sem prévio aviso e sem nenhum direito a indenização a quem quer que seja, que contenha: "
                            ),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 9.7.1. ofensa à honra, imagem, reputação ou dignidade de terceiros; "
                            ),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 9.7.2. pornografia, pedofilia ou outras modalidades de satisfação sexual; "
                            ),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 9.7.3. racismo, xenofobismo ou discriminação de qualquer natureza; "
                            ),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 9.7.4. bullying, stalking ou qualquer outra espécie de constrangimento ilegal ou assédio; "
                            ),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 9.7.5. violação a direito autoral ou direito de imagem; "
                            ),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 9.7.6. utilização de marcas, símbolos, logotipos, sinais distintivos ou emblemas de terceiros; "
                            ),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 9.7.7. instigação ou apologia à prática de quaisquer atos ilícitos de natureza cível ou criminal; "
                            ),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 9.7.8. conteúdos que incitem o uso da violência, a disseminação de ódio ou qualquer outra forma de agressão ou afronta ao bem-estar físico, social ou psicológico de alguém; "
                            ),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 9.7.9. que se relacionem com atividade associativa ou comercial similar à da "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v("; "),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 9.7.10. que utilizem a plataforma para angariar recursos por publicidade de terceiros; "
                            ),
                          ]),
                          _c("p", { staticClass: "paragraph-forwards" }, [
                            _vm._v(
                              " 9.7.11. que contenham erros flagrantes, fortes indícios de equívocos, conteúdo impróprio ou dados insuscetíveis de comprovação. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 9.8. Será resguardado o sigilo e preservada a identidade do usuário que, presenciando ou constatando um ato potencialmente ilícito no portal ou aplicativo, entender por bem denunciá-lo à "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              ", salvo se, por força de ordem judicial, for determinada a retirada do sigilo e divulgação da identidade do autor da denúncia. "
                            ),
                          ]),
                          _c(
                            "h5",
                            { staticClass: "post-title font-weight--light" },
                            [
                              _vm._v(
                                " 10. Atendimento do portal ou aplicativo "
                              ),
                            ]
                          ),
                          _c("p", [
                            _vm._v(" 10.1. A "),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " atenderá os usuários de seu portal ou aplicativo pelos meios de contato nele disponíveis. "
                            ),
                          ]),
                          _c(
                            "h5",
                            { staticClass: "post-title font-weight--light" },
                            [
                              _vm._v(
                                " 11. Modificações destes termos e condições "
                              ),
                            ]
                          ),
                          _c("p", [
                            _vm._v(
                              " 11.1. Os presentes Termos de Uso e Condições de Navegação estão sujeitos a constante melhoria e aprimoramento, motivo pelo qual a "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " se reserva o direito de modificá-lo a qualquer momento, de forma unilateral. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 11.2. Ao navegar por este portal ou aplicativo e utilizar suas funcionalidades, você aceita guiar-se pelos Termos e Condições de Uso do Portal ou Aplicativo "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " e pela Política de Privacidade que se encontram vigentes na data, devendo, portanto, verificá-los a cada vez que visitar ou navegar no portal ou aplicativo. "
                            ),
                          ]),
                          _c(
                            "h5",
                            { staticClass: "post-title font-weight--light" },
                            [_vm._v(" 12. Disposições gerais ")]
                          ),
                          _c("p", [
                            _vm._v(
                              " 12.1. A tolerância acerca do eventual descumprimento de quaisquer das cláusulas e condições do presente instrumento não constituirá novação das obrigações aqui estipuladas, tampouco impedirá ou inibirá a exigibilidade delas a qualquer tempo. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 12.2. Caso alguma disposição deste Termo de Uso ou da Política de Privacidade disponibilizada no portal ou aplicativo venha a ser julgada inaplicável ou sem efeito, as demais cláusulas e disposições de ambos os documentos continuarão vigorando plenamente. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(" 12.3. A "),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " poderá a qualquer momento, sem aviso prévio ou posterior, suspender, cancelar ou interromper o acesso aos sites e aplicativos, inclusive se o uso desses canais contrariar o disposto neste documento. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 12.4. Este portal ou aplicativo tem como base o horário oficial de Brasília. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " 12.5. A utilização deste portal ou aplicativo e a oferta dos serviços da "
                            ),
                            _c("strong", [_vm._v("Educatena")]),
                            _vm._v(
                              " são exclusivamente direcionadas para pessoas jurídicas e pessoas físicas com plena capacidade civil. "
                            ),
                          ]),
                          _c(
                            "h5",
                            { staticClass: "post-title font-weight--light" },
                            [_vm._v(" 13. Lei aplicável e jurisdição ")]
                          ),
                          _c("p", [
                            _vm._v(
                              " 13.1. Os Termos e Condições de Uso aqui descritos são interpretados segundo a legislação brasileira, no idioma português, sendo eleito o Foro Central da Comarca de Brasília Distrito Federal para dirimir qualquer litígio, questão ou dúvida, com expressa renúncia de qualquer outro, por mais privilegiado que seja. "
                            ),
                          ]),
                          _c("p"),
                          _c("p", [
                            _vm._v("Atualizada em 03 de janeiro de 2021"),
                          ]),
                          _c("p", [_c("strong", [_vm._v("Educatena")])]),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }